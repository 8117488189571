var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "equipment", attrs: { id: "ruleWrapper" } }, [
    _c("div", { staticClass: "breadcrumb" }),
    _c("div", { staticClass: "e_content" }, [
      _c("div", { staticClass: "ruleMessage1" }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _vm._v("计费规则信息 "),
            _c("i", {
              staticClass: "el-icon-question",
              staticStyle: { "font-size": "14px" },
              on: {
                mouseover: function ($event) {
                  _vm.ruleDefault = true
                },
                mouseout: function ($event) {
                  _vm.ruleDefault = false
                },
              },
            }),
            _c("transition", { attrs: { name: "slide-fade" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ruleDefault,
                      expression: "ruleDefault",
                    },
                  ],
                  staticClass: "ruleDefault",
                  on: {
                    mouseover: function ($event) {
                      _vm.ruleDefault = true
                    },
                    mouseout: function ($event) {
                      _vm.ruleDefault = false
                    },
                  },
                },
                [
                  _vm._v(" 以下内容为系统默认计费规则，无需配置："),
                  _c("br"),
                  _vm._v(
                    "   （1）每个自然日为一个独立计费周期，计费周期内按对应收费时段收费，下一个计费周期重新计费；"
                  ),
                  _c("br"),
                  _vm._v(
                    "   （2）停车后15分钟内免费停车。停放超过15分钟的，前15分钟纳入计费停车时长。有欠费的机动车不享受该免费政策；"
                  ),
                  _c("br"),
                  _vm._v(
                    "   （3）同一车辆在同一路段连续发生2次及以上停放行为的，若相邻两次停放的时间间隔低于半小时，则延续前一次停车时长进行计费；"
                  ),
                  _c("br"),
                  _vm._v(
                    "   （4）消防车、救护车、应急抢险车、军警车辆以及法律、法规规定应当免收机动车停放服务费的车辆使用临时泊位的，免于支付使用费。"
                  ),
                  _c("br"),
                  _vm._v(
                    "   （5）残疾人机动轮椅车在残疾人机动轮椅车专用泊位停放的，免予支付使用费。若停放在非专用泊位上的，使用费按规定标准减半支付；"
                  ),
                  _c("br"),
                  _vm._v(
                    "   （6）在每个计费周期的收费时段内，新能源汽车停放充电桩泊位并充电的，免予支付首次充电首一小时（含）的泊位使用费。新能源巡游出租车还可以享受在执行行政性事业性收费的非重点路段泊位每日停放三次、每次停车时长不超过（含）一小时的免费停车优惠。前述两类车辆，如停车时长超过一小时的，不再享受本款优惠政策，应当支付全部停车时长的泊位使用费。 "
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("top-compont", {
              ref: "topCon",
              model: {
                value: _vm.formInline,
                callback: function ($$v) {
                  _vm.formInline = $$v
                },
                expression: "formInline",
              },
            }),
            _c("body-compont", {
              ref: "bodyCon",
              model: {
                value: _vm.formInline.source,
                callback: function ($$v) {
                  _vm.$set(_vm.formInline, "source", $$v)
                },
                expression: "formInline.source",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticStyle: {
            margin: "0 auto",
            width: "250px",
            "margin-bottom": "20px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.submitData()
                },
              },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }